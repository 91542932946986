<template>
  <v-container
    id="client-forms"
    fluid
    tag="section"
  >
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="150"
          :width="8"
          color="green"
        />
      </div>
    </v-container>
    <v-row justify="center">
      <v-col
        v-if="!loading"
        cols="12"
        md="12"
        class="mt-0 mb-0 pt-0 pb-0"
      >
        <v-breadcrumbs
          class="mt-0 mb-0 pt-0 pb-0"
          :items="[{text: 'Ваш центр', to: '/'}, {text: 'Преподаватели', to: '/teachers'}, {text: title, to: `/teacher/${id}`} , {text: 'Отчет о проведенных занятиях'}]"
          large
        />
      </v-col>
      <v-col
        cols="12"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          v-if="!loading"
          color="teal"
          icon="mdi-chart-areaspline  "
          class="px-5 py-3"
          :title="`${teacher.name} - Проведенные занятия`"
        >
          <v-row
            justify="center"
          >
            <v-col
              cols="12"
              lg="3"
            >
              <h1>Всего за {{ periodTitle }}</h1>
              <div><b>Минут занятий:</b> {{ totalDuration }}</div>
              <div>
                <b>Учтенных академических часов:</b> {{ totalAccountedAkademHours }}&nbsp;из&nbsp;{{ totalAkademHours
                }}
              </div>
              <div><b>Учтенных занятий:</b> {{ lessonsAccountedCount }}&nbsp;из&nbsp;{{ lessonsCount }}</div>
              <div><b>Сумма оплаты за академические часы:</b> {{ totalPay }}</div>
            </v-col>
            <v-col
              cols="12"
              lg="9"
            >
              <v-row>
                <v-col
                  cols="12"
                  lg="12"
                >
                  <v-btn-toggle
                    v-model="period"
                    rounded
                    mandatory
                  >
                    <v-btn
                      color="secondary"
                      small
                    >
                      Весь период
                    </v-btn>
                    <v-btn
                      color="secondary"
                      small
                    >
                      Текущий месяц
                    </v-btn>
                    <v-btn
                      color="secondary"
                      small
                    >
                      Предыдущий месяц
                    </v-btn>
                    <v-btn
                      color="secondary"
                      small
                    >
                      Последние 30 дней
                    </v-btn>
                    <v-btn
                      disabled
                      color="secondary"
                      small
                    >
                      Другой период
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <!--<v-col
                  cols="12"
                  lg="4"
                >
                  <v-text-field
                    v-model="start"
                    required
                    label="Начало периода отчета"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="4"
                >
                  <v-text-field
                    v-model="end"
                    required
                    label="Конец периода отчета"
                  />
                </v-col> -->

                <v-col
                  cols="12"
                  lg="4"
                >
                  <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    min-width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        readonly
                        :value="start"
                        label="с"
                        color="secondary"
                        prepend-icon="mdi-calendar-outline"
                        messages="Дата начала периода отчета"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="start"
                      color="secondary"
                      locale="ru-ru"
                      landscape
                      scrollable
                      first-day-of-week="1"
                      @input="startDateMenu = false"
                    >
                      <v-spacer />

                      <v-btn
                        color="secondary"
                        large
                        @click="startDateMenu = false"
                      >
                        Cancel
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  lg="4"
                >
                  <v-menu
                    ref="endDateMenu"
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="endDate"
                    transition="scale-transition"
                    min-width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        readonly
                        :value="end"
                        label="по"
                        color="secondary"
                        prepend-icon="mdi-calendar-outline"
                        messages="Дата окончания периода отчета"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="end"
                      color="secondary"
                      locale="ru-ru"
                      landscape
                      scrollable
                      first-day-of-week="1"
                      @input="endDateMenu = false"
                    >
                      <v-spacer />

                      <v-btn
                        color="secondary"
                        large
                        @click="endDateMenu = false"
                      >
                        Отмена
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  lg="4"
                >
                  <v-btn
                    class="ma-1"
                    color="secondary"
                    rounded
                    @click="fetchData()"
                  >
                    Применить
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              v-if="teacher.status === 0"
              cols="12"
              md="12"
            >
              <base-material-alert
                v-if="teacher.status === 0"
                color="warning"
                dark
                icon="mdi-zip-disk"
              >
                Преподаватель в архиве!
              </base-material-alert>
            </v-col>

            <v-col
              cols="12"
              md="12"
            >
              <v-data-table
                :loading="loading"
                loading-text="Загрузка..."
                :headers="headers"
                :items="lessons"
                :search="search"
                :items-per-page.sync="itemsPerPage"
                :footer-props="{
                  'items-per-page-options': [-1]
                }"
                :page.sync="page"
                :sort-by="['datetime']"
                :sort-desc="[true]"
                class="lessons"
              >
                <template v-slot:item.akadem="{ item }">
                  {{ Math.ceil(item.schedule.duration/60) }}
                </template>
                <template v-slot:item.clientsCount="{ item }">
                  <v-chip
                    v-if="item.clientsCount > 0"
                    x-small
                  >
                    {{ item.clientsCount }}
                  </v-chip>
                  <v-chip
                    v-else-if="item.clientsCount === 0 && !item.canceled"
                    x-small
                    class="warning"
                    title="50% от ставки за час (см. примечания внизу таблицы)**"
                  >
                    {{ item.clientsCount }}
                  </v-chip>
                </template>
                <template v-slot:item.akadem_pay="{ item }">
                  <v-chip
                    v-if="item.clientsCount > 0"
                    x-small
                  >
                    {{ Math.ceil(item.schedule.duration / 60) * teacher.tax }}
                  </v-chip>
                  <v-chip
                    v-else-if="item.clientsCount === 0 && !item.canceled"
                    x-small
                    class="warning"
                    title="50% от ставки за час (см. примечания внизу таблицы)**"
                  >
                    {{ Math.ceil(item.schedule.duration / 60) * 0.5 * teacher.tax }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-col>
            <v-col
              cols="12"
              lg="12"
            >
              <p class="text--disabled">
                * Один академический час — это занятие длительностью от 30 до 60 минут. Например, если длительность
                занятия в расписании указана 120 минут, то это будет считаться одним занятием, но длительностью 2
                академических часа. Оплата за данное занятие будет рассчитана как за 2 академических часа по тарифу в
                карточке преподавателя <em>(у {{ teacher.name }} тариф {{ teacher.tax }} за
                  один академический час)</em>.
              </p>
              <p class="text--disabled">
                ** Если занятие было отмечено в учебной сетке, но на нем не присутствовало ни одного ученика, оплата за
                такое занятие считается по тарифу 50% от ставки преподавателя за академически час
                <em>(у {{ teacher.name }} это {{ teacher.tax }}-50%={{ teacher.tax / 2 }})</em>.
                <b>Если оплата за такое занятие не предусмотрена, то необходимо либо удалить запись о занятии, либо
                  отметить его как отмененное.</b>
              </p>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
  .v-item--active.v-btn--active {
    opacity: 0.5;
  }
</style>
<script>
  import moment from 'moment'
  import teacherApi from '../services/TeachersApi'
  import lessonsApi from '../services/LessonsApi'
  import CRMStorage from '../services/CRMStorage'

  export default {
    props: ['id'],
    data () {
      return {
        photo: null,
        teacher: {
          id: null,
          name: null,
        },
        lessons: [],
        start: this.last30Start(),
        end: this.last30End(),
        period: 3,

        search: CRMStorage.getItem('TCHST_search', ''),
        page: 1,
        itemsPerPage: CRMStorage.getItem('TCHST_itemsPerPage', -1),
        headers: [
          {
            sortable: true,
            text: 'Id',
            value: 'id',
          },
          {
            sortable: true,
            text: 'Дата и время начала',
            value: 'datetime',
          },
          {
            sortable: true,
            text: 'Группа по расписанию',
            value: 'timetable.name',
          },
          {
            sortable: true,
            text: 'Кабинет',
            value: 'room.name',
          },
          {
            sortable: true,
            text: 'Присутв. учащихся',
            value: 'clientsCount',
          },
          {
            sortable: true,
            text: 'Прод-ть, мин',
            value: 'schedule.duration',
          },
          {
            sortable: true,
            text: 'Акад. часов*',
            value: 'akadem',
          },
          {
            sortable: true,
            text: 'Оплата за акад. часы**',
            value: 'akadem_pay',
          },
          /* {
            sortable: true,
            text: 'Действия',
          }, */
        ],

        loading: true,
        error: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
        date: '',

        startDateMenu: false,
        endDateMenu: false,
        startDate: '',
        endDate: '',
      }
    },

    computed: {
      periodTitle () {
        if (this.period === 0) { // весь период
          return 'весь период'
        } else if (this.period === 1) { // текущий месяц
          return 'текущий месяц'
        } else if (this.period === 2) { // предыдущий месяц
          return 'предыдущий месяц'
        } else if (this.period === 3) { // Последние 30 дней
          return 'последние 30 дней'
        }
        return 'период c ' + this.start + ' по ' + this.end
      },
      title () {
        if (this.teacher.name) return this.teacher.name
        return '#' + this.id
      },
      totalDuration () {
        if (this.lessons.length > 0) {
          let total = 0
          this.lessons.forEach(lesson => {
            total += lesson.schedule.duration
          })
          return total
        }
        return '-'
      },
      totalAkademHours () {
        if (this.lessons.length > 0) {
          let total = 0
          this.lessons.forEach(lesson => {
            total += Math.ceil(lesson.schedule.duration / 60)
          })
          return total
        }
        return '-'
      },
      totalAccountedAkademHours () {
        if (this.lessons.length > 0) {
          let total = 0
          this.lessons.forEach(lesson => {
            if (!lesson.canceled) {
              total += Math.ceil(lesson.schedule.duration / 60)
            }
          })
          return total
        }
        return '-'
      },
      lessonsAccountedCount () {
        if (this.lessons.length > 0) {
          let total = 0
          this.lessons.forEach(lesson => {
            if (!lesson.canceled) {
              total++
            }
          })
          return total
        }
        return '-'
      },
      lessonsCount () {
        if (this.lessons.length > 0) {
          return this.lessons.length
        }
        return '-'
      },
      totalPay () {
        if (this.lessons.length > 0) {
          let total = 0
          this.lessons.forEach(lesson => {
            if (lesson.clientsCount > 0) {
              total += Math.ceil(lesson.schedule.duration / 60) * this.teacher.tax
            } else if (lesson.clientsCount === 0 && lesson.canceled) {
              total += Math.ceil(lesson.schedule.duration / 60) * this.teacher.tax * 0.5
            }
          })
          return total
        }
        return '-'
      },
    },

    watch: {
      period: function (val) {
        if (val === 0) { // весь период
          if (this.start !== null) this.start = null
          if (this.end !== null) this.end = null
        } else if (val === 1) { // текущий месяц
          if (this.start !== this.curMonthStart()) this.start = this.curMonthStart()
          if (this.end !== this.curMonthEnd()) this.end = this.curMonthEnd()
        } else if (val === 2) { // предыдущий месяц
          if (this.start !== this.prevMonthStart()) this.start = this.prevMonthStart()
          if (this.end !== this.prevMonthEnd()) this.end = this.prevMonthEnd()
        } else if (val === 3) { // Последние 30 дней
          if (this.start !== this.last30Start()) this.start = this.last30Start()
          if (this.end !== this.last30End()) this.end = this.last30End()
        }
        if (val !== 4) { this.fetchData() }
      },

      start: function (val) {
        if (!val && !this.end) {
          if (this.period !== 0) this.period = 0
        } else if (val === this.curMonthStart() && this.end === this.curMonthEnd()) {
          if (this.period !== 1) this.period = 1
        } else if (val === this.prevMonthStart() && this.end === this.prevMonthEnd()) {
          if (this.period !== 2) this.period = 2
        } else if (val === this.last30Start() && this.end === this.last30End()) {
          if (this.period !== 3) this.period = 3
        } else {
          if (this.period !== 4) this.period = 4
        }
      },

      end: function (val) {
        if (!val && !this.start) {
          if (this.period !== 0) this.period = 0
        } else if (this.start === this.curMonthStart() && val === this.curMonthEnd()) {
          if (this.period !== 1) this.period = 1
        } else if (this.start === this.prevMonthStart() && val === this.prevMonthEnd()) {
          if (this.period !== 2) this.period = 2
        } else if (this.start === this.last30Start() && val === this.last30End()) {
          if (this.period !== 3) this.period = 3
        } else {
          if (this.period !== 4) this.period = 4
        }
      },

      search: function (val) {
        this.page = 1
        CRMStorage.setItem('TCHRL_search', val)
      },
      itemsPerPage: function (val) {
        CRMStorage.setItem('TCHRL_itemsPerPage', val)
      },
      page: function (val) {
        CRMStorage.setItem('TCHRL_page', val)
      },
    },

    mounted () {
      document.title = this.title + ' | ' + process.env.VUE_APP_NAME
      moment.locale('ru')
      this.fetchData()
    },

    methods: {
      fetchData () {
        this.loading = true
        teacherApi
          .fetchTeacher(this.id)
          .then(response => {
            this.teacher = response
            document.title = this.title + ' | ' + process.env.VUE_APP_NAME
          }).catch(error => {
            this.error = this.pretty(error.response.data)
            this.loading = false
          })

        if (this.start && this.end) {
          lessonsApi
            .fetchLessonsByTeacherAndDates(this.id, this.start, this.end)
            .then(response => {
              this.lessons = response
              this.loading = false
            }).catch(error => {
              this.error = this.pretty(error.response.data)
              this.loading = false
            })
        } else {
          lessonsApi
            .fetchLessonsByTeacher(this.id)
            .then(response => {
              this.lessons = response
              this.loading = false
            }).catch(error => {
              this.error = this.pretty(error.response.data)
              this.loading = false
            })
        }
      },

      back () {
        this.$router.push('/')
      },
      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },
      formatDate (date) {
        if (!date) return null
        return moment(date).format('DD.MM.YYYY')
      },

      imageLink (photo) {
        return process.env.VUE_APP_OLD_URL + 'images/teachers/' + photo
      },

      getBase64 (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
      },

      prevMonthStart () {
        return moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
      },

      prevMonthEnd () {
        return moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
      },

      curMonthStart () {
        return moment().clone().startOf('month').format('YYYY-MM-DD')
      },

      curMonthEnd () {
        return moment().clone().endOf('month').format('YYYY-MM-DD')
      },

      last30Start () {
        return moment().add(-30, 'days').format('YYYY-MM-DD')
      },

      last30End () {
        return moment().format('YYYY-MM-DD')
      },
    },
  }
</script>
