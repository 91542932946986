import axios from 'axios'

export default {
  fetchLessonsByDates (start, end) {
    return axios.get('lessons/bydates?start=' + start + '&end=' + end).then(response => {
      return response.data
    })
  },

  fetchLessonsByTeacher (teacherId) {
    return axios.get('lessons?expand=timetable,clients,clientsCount,room,schedule&teacher_id=' + teacherId).then(response => {
      return response.data
    })
  },

  fetchLessonsByTeacherAndDates (teacherId, start, end) {
    return axios.get('lessons?expand=timetable,clients,clientsCount,room,schedule&teacher_id=' + teacherId + '&from_date=' + start + '&to_date=' + end).then(response => {
      return response.data
    })
  },

  updateLesson (lesson) {
    return axios.put('lessons/' + lesson.id, lesson).then(response => {
      return response.data
    })
  },

  createLesson (lesson) {
    return axios.post('lessons', lesson).then(response => {
      return response.data
    })
  },

  deleteLesson (id) {
    return axios.delete('lessons/' + id).then(response => {
      return response.data
    })
  },

  fetchLesson (id) {
    return axios.get('lessons/' + id +
      '?expand=schedule,timetable,clses,clses.subscription,clses.client,teachersIds,clientsIds,clients,teachers')
      .then(response => {
        return response.data
      })
  },
}
