var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "client-forms", fluid: "", tag: "section" } },
    [
      _vm.loading
        ? _c("v-container", [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("v-progress-circular", {
                  attrs: {
                    indeterminate: "",
                    size: 150,
                    width: 8,
                    color: "green"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          !_vm.loading
            ? _c(
                "v-col",
                {
                  staticClass: "mt-0 mb-0 pt-0 pb-0",
                  attrs: { cols: "12", md: "12" }
                },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "mt-0 mb-0 pt-0 pb-0",
                    attrs: {
                      items: [
                        { text: "Ваш центр", to: "/" },
                        { text: "Преподаватели", to: "/teachers" },
                        { text: _vm.title, to: "/teacher/" + _vm.id },
                        { text: "Отчет о проведенных занятиях" }
                      ],
                      large: ""
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.error
                ? _c(
                    "base-material-alert",
                    { attrs: { dark: "", color: "error", dismissible: "" } },
                    [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "base-material-card",
                    {
                      staticClass: "px-5 py-3",
                      attrs: {
                        color: "teal",
                        icon: "mdi-chart-areaspline  ",
                        title: _vm.teacher.name + " - Проведенные занятия"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c("v-col", { attrs: { cols: "12", lg: "3" } }, [
                            _c("h1", [
                              _vm._v("Всего за " + _vm._s(_vm.periodTitle))
                            ]),
                            _c("div", [
                              _c("b", [_vm._v("Минут занятий:")]),
                              _vm._v(" " + _vm._s(_vm.totalDuration))
                            ]),
                            _c("div", [
                              _c("b", [
                                _vm._v("Учтенных академических часов:")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.totalAccountedAkademHours) +
                                  " из " +
                                  _vm._s(_vm.totalAkademHours) +
                                  "\n            "
                              )
                            ]),
                            _c("div", [
                              _c("b", [_vm._v("Учтенных занятий:")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.lessonsAccountedCount) +
                                  " из " +
                                  _vm._s(_vm.lessonsCount)
                              )
                            ]),
                            _c("div", [
                              _c("b", [
                                _vm._v("Сумма оплаты за академические часы:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.totalPay))
                            ])
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "9" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", lg: "12" } },
                                    [
                                      _c(
                                        "v-btn-toggle",
                                        {
                                          attrs: { rounded: "", mandatory: "" },
                                          model: {
                                            value: _vm.period,
                                            callback: function($$v) {
                                              _vm.period = $$v
                                            },
                                            expression: "period"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                small: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Весь период\n                  "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                small: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Текущий месяц\n                  "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                small: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Предыдущий месяц\n                  "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                small: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Последние 30 дней\n                  "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled: "",
                                                color: "secondary",
                                                small: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Другой период\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", lg: "4" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "startDateMenu",
                                          attrs: {
                                            "close-on-content-click": false,
                                            "return-value": _vm.startDate,
                                            transition: "scale-transition",
                                            "min-width": "290px",
                                            "offset-y": ""
                                          },
                                          on: {
                                            "update:returnValue": function(
                                              $event
                                            ) {
                                              _vm.startDate = $event
                                            },
                                            "update:return-value": function(
                                              $event
                                            ) {
                                              _vm.startDate = $event
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            readonly: "",
                                                            value: _vm.start,
                                                            label: "с",
                                                            color: "secondary",
                                                            "prepend-icon":
                                                              "mdi-calendar-outline",
                                                            messages:
                                                              "Дата начала периода отчета"
                                                          }
                                                        },
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2447543535
                                          ),
                                          model: {
                                            value: _vm.startDateMenu,
                                            callback: function($$v) {
                                              _vm.startDateMenu = $$v
                                            },
                                            expression: "startDateMenu"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-date-picker",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                locale: "ru-ru",
                                                landscape: "",
                                                scrollable: "",
                                                "first-day-of-week": "1"
                                              },
                                              on: {
                                                input: function($event) {
                                                  _vm.startDateMenu = false
                                                }
                                              },
                                              model: {
                                                value: _vm.start,
                                                callback: function($$v) {
                                                  _vm.start = $$v
                                                },
                                                expression: "start"
                                              }
                                            },
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "secondary",
                                                    large: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.startDateMenu = false
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Cancel\n                    "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", lg: "4" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "endDateMenu",
                                          attrs: {
                                            "close-on-content-click": false,
                                            "return-value": _vm.endDate,
                                            transition: "scale-transition",
                                            "min-width": "290px",
                                            "offset-y": ""
                                          },
                                          on: {
                                            "update:returnValue": function(
                                              $event
                                            ) {
                                              _vm.endDate = $event
                                            },
                                            "update:return-value": function(
                                              $event
                                            ) {
                                              _vm.endDate = $event
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            readonly: "",
                                                            value: _vm.end,
                                                            label: "по",
                                                            color: "secondary",
                                                            "prepend-icon":
                                                              "mdi-calendar-outline",
                                                            messages:
                                                              "Дата окончания периода отчета"
                                                          }
                                                        },
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            3014024843
                                          ),
                                          model: {
                                            value: _vm.endDateMenu,
                                            callback: function($$v) {
                                              _vm.endDateMenu = $$v
                                            },
                                            expression: "endDateMenu"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-date-picker",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                locale: "ru-ru",
                                                landscape: "",
                                                scrollable: "",
                                                "first-day-of-week": "1"
                                              },
                                              on: {
                                                input: function($event) {
                                                  _vm.endDateMenu = false
                                                }
                                              },
                                              model: {
                                                value: _vm.end,
                                                callback: function($$v) {
                                                  _vm.end = $$v
                                                },
                                                expression: "end"
                                              }
                                            },
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "secondary",
                                                    large: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.endDateMenu = false
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Отмена\n                    "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", lg: "4" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-1",
                                          attrs: {
                                            color: "secondary",
                                            rounded: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.fetchData()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Применить\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.teacher.status === 0
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _vm.teacher.status === 0
                                    ? _c(
                                        "base-material-alert",
                                        {
                                          attrs: {
                                            color: "warning",
                                            dark: "",
                                            icon: "mdi-zip-disk"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              Преподаватель в архиве!\n            "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c("v-data-table", {
                                staticClass: "lessons",
                                attrs: {
                                  loading: _vm.loading,
                                  "loading-text": "Загрузка...",
                                  headers: _vm.headers,
                                  items: _vm.lessons,
                                  search: _vm.search,
                                  "items-per-page": _vm.itemsPerPage,
                                  "footer-props": {
                                    "items-per-page-options": [-1]
                                  },
                                  page: _vm.page,
                                  "sort-by": ["datetime"],
                                  "sort-desc": [true]
                                },
                                on: {
                                  "update:itemsPerPage": function($event) {
                                    _vm.itemsPerPage = $event
                                  },
                                  "update:items-per-page": function($event) {
                                    _vm.itemsPerPage = $event
                                  },
                                  "update:page": function($event) {
                                    _vm.page = $event
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item.akadem",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                Math.ceil(
                                                  item.schedule.duration / 60
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.clientsCount",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          item.clientsCount > 0
                                            ? _c(
                                                "v-chip",
                                                { attrs: { "x-small": "" } },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        item.clientsCount
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            : item.clientsCount === 0 &&
                                              !item.canceled
                                            ? _c(
                                                "v-chip",
                                                {
                                                  staticClass: "warning",
                                                  attrs: {
                                                    "x-small": "",
                                                    title:
                                                      "50% от ставки за час (см. примечания внизу таблицы)**"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        item.clientsCount
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.akadem_pay",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          item.clientsCount > 0
                                            ? _c(
                                                "v-chip",
                                                { attrs: { "x-small": "" } },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        Math.ceil(
                                                          item.schedule
                                                            .duration / 60
                                                        ) * _vm.teacher.tax
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            : item.clientsCount === 0 &&
                                              !item.canceled
                                            ? _c(
                                                "v-chip",
                                                {
                                                  staticClass: "warning",
                                                  attrs: {
                                                    "x-small": "",
                                                    title:
                                                      "50% от ставки за час (см. примечания внизу таблицы)**"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        Math.ceil(
                                                          item.schedule
                                                            .duration / 60
                                                        ) *
                                                          0.5 *
                                                          _vm.teacher.tax
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  589251984
                                )
                              })
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "12", lg: "12" } }, [
                            _c("p", { staticClass: "text--disabled" }, [
                              _vm._v(
                                "\n              * Один академический час — это занятие длительностью от 30 до 60 минут. Например, если длительность\n              занятия в расписании указана 120 минут, то это будет считаться одним занятием, но длительностью 2\n              академических часа. Оплата за данное занятие будет рассчитана как за 2 академических часа по тарифу в\n              карточке преподавателя "
                              ),
                              _c("em", [
                                _vm._v(
                                  "(у " +
                                    _vm._s(_vm.teacher.name) +
                                    " тариф " +
                                    _vm._s(_vm.teacher.tax) +
                                    " за\n                один академический час)"
                                )
                              ]),
                              _vm._v(".\n            ")
                            ]),
                            _c("p", { staticClass: "text--disabled" }, [
                              _vm._v(
                                "\n              ** Если занятие было отмечено в учебной сетке, но на нем не присутствовало ни одного ученика, оплата за\n              такое занятие считается по тарифу 50% от ставки преподавателя за академически час\n              "
                              ),
                              _c("em", [
                                _vm._v(
                                  "(у " +
                                    _vm._s(_vm.teacher.name) +
                                    " это " +
                                    _vm._s(_vm.teacher.tax) +
                                    "-50%=" +
                                    _vm._s(_vm.teacher.tax / 2) +
                                    ")"
                                )
                              ]),
                              _vm._v(".\n              "),
                              _c("b", [
                                _vm._v(
                                  "Если оплата за такое занятие не предусмотрена, то необходимо либо удалить запись о занятии, либо\n                отметить его как отмененное."
                                )
                              ])
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }